import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import Grow from "@mui/material/Grow";
import TaskSearchItem from "./taskSearchItem";
import { SEARCH_TASK } from "../../../../gqls/task";
import { task } from "../../../../types/task";
import { useCheckAuth } from "../../../../store/authStore";
import { SEARCH_CONTACT } from "../../../../gqls/contact";
import { sort } from "fast-sort";

export default function TaskSearch() {
  const [str, setStr] = useState("");

  const debouncedStr = useDebounce(str, 1000);
  const [results, setResults] = useState<task[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const checkAuth = useCheckAuth();

  const [search] = useLazyQuery(SEARCH_TASK, {
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      const searchResults = data.tasks || [];

      // console.log(searchResults);
      setResults(
        searchResults.filter(task =>
          checkAuth({
            permissionName: "작업열람",
            userId: task.assignedToId,
          })
        )
      );
      inputRef.current?.blur();
    },
  });

  const [searchContacts] = useLazyQuery(SEARCH_CONTACT);

  const searchFunc = async (Str: string) => {
    const yearNumber = Str.split(/[-~ ]/).map(n =>
      Number(n.replace(/[^0-9\.]+/g, ""))
    );

    let year = yearNumber[0] || 0;

    let number = yearNumber[1] || 0;

    if (year > 10000) {
      year = 0;
    }

    if (number > 100000) {
      number = 0;
    }

    const where: any = {
      _or: [
        { customer: { name: { _regex: Str } } },
        { customer: { address: { _regex: Str } } },
        { description: { _regex: Str } },
        { report: { serials: { _contains: Str } } },
        {
          _and: {
            customer: { year: { _eq: year }, number: { _eq: number } },
          },
        },
      ],
    };

    let contactIds: number[] = [];

    const res = await searchContacts({
      variables: { Str },
      fetchPolicy: "network-only",
    });

    const results = res.data?.contacts?.map(c => c.id) || [];

    contactIds.push(...results);

    if (contactIds.length > 0) {
      where._or.push({
        customer: {
          _and: {
            contactIds: { _contained_in: contactIds, _neq: [] },
          },
        },
      });

      where._or.push({
        customer: {
          contactIds: { _contains: contactIds },
        },
      });
    }

    search({
      variables: { where },
    });

    return;
  };

  useEffect(() => {
    if (debouncedStr.trim() == "") {
      return setResults([]);
    }
    searchFunc(debouncedStr);
  }, [debouncedStr]);

  return (
    <div className="w-full md:w-80 max-w-[100dvw] relative flex flex-row items-center gap-3">
      <div className="absolute left-2">
        <SearchIcon />
      </div>
      <input
        ref={inputRef}
        className="flex-1 w-0 ring-1 ring-gray-300 placeholder:text-gray-400 text-black bg-white rounded-sm px-2 py-[6px] pl-10 outline-none shadow-md md:shadow-none"
        style={{
          fontWeight: 500,
        }}
        placeholder="작업 검색"
        value={str}
        onChange={e => setStr(e.target.value)}
      />
      <Divider orientation="vertical" flexItem />
      <Grow in={results.length > 0}>
        <div className="absolute top-full mt-2 w-full pr-3 z-10">
          <div className="flex flex-col gap-2 p-2 rounded-sm bg-white shadow-md w-full ring-1 ring-gray-300">
            {sort(results)
              .desc("id")
              .map((res, i) => (
                <TaskSearchItem
                  key={res.id}
                  task={res}
                  setStr={setStr}
                  index={i}
                />
              ))}
          </div>
        </div>
      </Grow>
    </div>
  );
}
